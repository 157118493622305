var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"row":"","wrap":""}},[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('i-date-picker',{attrs:{"label":_vm.$t('start_date'),"color":"primary"},model:{value:(_vm.start),callback:function ($$v) {_vm.start=$$v},expression:"start"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('i-date-picker',{attrs:{"label":_vm.$t('end_date'),"color":"primary","min":_vm.start},model:{value:(_vm.end),callback:function ($$v) {_vm.end=$$v},expression:"end"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('ValidationProvider',{attrs:{"vid":"template","name":_vm.$t('template')},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"autocomplete":"off","outlined":"","error-messages":errors[0],"item-text":"name","item-value":"pk","items":_vm.templates,"label":_vm.$t('template')},model:{value:(_vm.form.template),callback:function ($$v) {_vm.$set(_vm.form, "template", $$v)},expression:"form.template"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('ValidationProvider',{attrs:{"vid":"designers","name":_vm.$t('designers')},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"autocomplete":"off","outlined":"","error-messages":errors[0],"item-text":(item) => `${item.first_name} ${item.last_name}`,"item-value":"pk","items":_vm.designers,"label":_vm.$t('designers')},model:{value:(_vm.form.designer),callback:function ($$v) {_vm.$set(_vm.form, "designer", $$v)},expression:"form.designer"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-btn',{attrs:{"color":"secondary"},on:{"click":function($event){return _vm.clear()}}},[_vm._v(" "+_vm._s(_vm.$t('clear'))+" ")])],1)],1),_c('i-table',{attrs:{"title":_vm.$t('designerReport'),"headers":_vm.headers,"app":"sales.order","opt":{
      date_from: this.form.date_from,
      date_to: this.form.date_to,
      template: this.form.template,
      company_id: this.company.pk,
      user_id: this.form.designer
    },"api":"suborder.designerReport","dontCreate":"","dontRemove":"","dontEdit":"","dontSearch":""},scopedSlots:_vm._u([{key:"item.date",fn:function({ item }){return [_c('span',{domProps:{"textContent":_vm._s(item.date != null ? _vm.dateFormat(item.date) : '')}})]}},{key:"extraToolbar",fn:function(){return [_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('i-btn',{attrs:{"text":"","small":_vm.$vuetify.breakpoint.smAndDown,"icon":"fa-file-excel","title":_vm.$t('download', { value: _vm.$tc('invoice', 2) }),"color":"text-dark"},on:{"click":function($event){return _vm.getExcel()}}})]},proxy:true}])}),_c('v-row',{attrs:{"row":"","wrap":""}},[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('Summary',{attrs:{"stats":_vm.stats}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }