<route>
  {
    "meta": {
      "permission": [
        "sales.view_order"
      ]
    }
  }
  </route>

<template>
  <v-container fluid>
    <v-row row wrap>
      <v-col cols="12" sm="3">
        <i-date-picker
          v-model="start"
          :label="$t('start_date')"
          color="primary"
        />
      </v-col>
      <v-col cols="12" sm="3">
        <i-date-picker
          v-model="end"
          :label="$t('end_date')"
          color="primary"
          :min="start"
        />
      </v-col>
      <v-col cols="12" sm="3">
        <ValidationProvider
          vid="template"
          :name="$t('template')"
          v-slot="{ errors }"
        >
          <v-select
            autocomplete="off"
            outlined
            :error-messages="errors[0]"
            v-model="form.template"
            item-text="name"
            item-value="pk"
            :items="templates"
            :label="$t('template')"
          ></v-select>
        </ValidationProvider>
      </v-col>
      <v-col cols="12" sm="3">
        <ValidationProvider
          vid="designers"
          :name="$t('designers')"
          v-slot="{ errors }"
        >
          <v-select
            autocomplete="off"
            outlined
            :error-messages="errors[0]"
            v-model="form.designer"
            :item-text="(item) => `${item.first_name} ${item.last_name}`"
            item-value="pk"
            :items="designers"
            :label="$t('designers')"
          ></v-select>
        </ValidationProvider>
      </v-col>
      <v-col cols="12" sm="3">
        <v-btn color="secondary" @click="clear()">
          {{ $t('clear') }}
        </v-btn>
      </v-col>
    </v-row>
    <i-table
      :title="$t('designerReport')"
      :headers="headers"
      app="sales.order"
      :opt="{
        date_from: this.form.date_from,
        date_to: this.form.date_to,
        template: this.form.template,
        company_id: this.company.pk,
        user_id: this.form.designer
      }"
      api="suborder.designerReport"
      dontCreate
      dontRemove
      dontEdit
      dontSearch
    >
      <template v-slot:item.date="{ item }">
        <span v-text="item.date != null ? dateFormat(item.date) : ''"></span>
      </template>
      <template v-slot:extraToolbar>
        <v-divider class="mx-4" inset vertical></v-divider>
        <i-btn
          text
          :small="$vuetify.breakpoint.smAndDown"
          icon="fa-file-excel"
          :title="$t('download', { value: $tc('invoice', 2) })"
          color="text-dark"
          @click="getExcel()"
        >
        </i-btn>
      </template>
    </i-table>
    <v-row row wrap>
      <v-col cols="12" sm="3">
        <Summary :stats="stats" />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import Summary from '../../components/globals/Summary.vue'

export default {
  components: {
    Summary
  },
  data() {
    return {
      modalBD: false,
      modalBD2: false,
      templates: [],
      start: '',
      end: '',
      form: {
        template: '',
        date_from: '',
        date_to: '',
        designer: ''
      },
      stats: [],
      designers: []
    }
  },
  async mounted() {
    const template = await this.$api.product.template.list({
      opt: {
        params: {
          order: true,
          company: this.company.pk
        }
      }
    })
    this.templates = [{ pk: '', name: this.$t('all') }, ...template.data]
    const response = await this.$api.sale.availableusers({
      opt: {
        params: {
          role: 'Designer'
        }
      }
    })
    this.designers = response.data.results
    await this.fetchStatistic()
  },
  computed: {
    ...mapGetters({
      me: 'session/me',
      company: 'company/getCompanyData'
    }),
    headers() {
      return [
        {
          text: this.$t('name'),
          value: 'user__first_name'
        },
        {
          text: this.$t('last_name'),
          value: 'user__last_name'
        },
        {
          text: this.$tc('suborder', 1),
          value: 'orderProduct__code'
        },
        {
          text: this.$t('subname'),
          value: 'orderProduct__name'
        },
        {
          text: this.$t('date'),
          value: 'orderProduct__order__date'
        },
        {
          text: this.$t('template'),
          value: 'orderProduct__product__template__name'
        }
      ]
    }
  },
  methods: {
    async fetchStatistic() {
      let params = {
        company_id: this.company.pk
      }
      if (this.form.date_from != '') {
        params.start_date = this.form.date_from
      }
      if (this.form.date_to != '') {
        params.end_date = this.form.date_to
      }
      if (this.form.template != '') {
        params.template = this.form.template
      }
      if (this.form.designer != '') {
        params.user_id = this.form.designer
      }
      const response = await this.$api.dashboard.overview_designer({
        opt: {
          params
        }
      })
      this.setStatisticData(response.data)
    },
    setStatisticData(values) {
      const keys = Object.keys(values)
      let stats_object = []
      for (const key of keys) {
        stats_object.push({
          label: key,
          value: Object.values(values[key]).reduce((a, b) => a + b, 0)
        })
      }
      this.stats = stats_object
    },
    getExcel() {
      this.downloadFile({
        api: 'suborder.designerReport',
        method: 'excelReport',
        filename: 'DesignerReport',
        action: 'download',
        ext: 'xlsx',
        opt: {
          params:
            '?template=' +
            this.form.template +
            '&company_id=' +
            this.company.pk +
            '&date_from=' +
            this.form.date_from +
            '&date_to=' +
            this.form.date_to +
            '&user_id=' +
            this.form.designer
        }
      })
    },
    clear() {
      this.form = {
        template: '',
        date_from: '',
        date_to: ''
      }
      this.start = ''
      this.end = ''
    }
  },
  watch: {
    start: {
      handler(val) {
        if (val != null && val != '' && val != undefined) {
          this.form.date_from = this.dateFormat(val)
          this.fetchStatistic()
        }
      }
    },
    end: {
      handler(val) {
        if (val != null && val != '') {
          this.form.date_to = this.dateFormat(val)
          this.fetchStatistic()
        }
      }
    },
    'form.template': {
      handler(val) {
        this.fetchStatistic()
      }
    },
    'form.designer': {
      handler(val) {
        this.fetchStatistic()
      }
    }
  }
}
</script>
