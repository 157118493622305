<template>
  <v-container fluid>
    <v-toolbar>
      <v-toolbar-title>{{ $t('summary') }}</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-list>
      <v-list-item v-for="(stat, index) in stats" :key="index">
        <v-list-item-content>
          <v-list-item-title class="primary--text">
            {{ stat.label }}
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          {{ stat.value }}
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-container>
</template>

<script>
export default {
  name: 'Summary',
  props: {
    stats: {
      type: Array,
      required: true,
      validator: (value) => {
        return value.every(
          (item) => item.hasOwnProperty('label') && item.hasOwnProperty('value')
        )
      }
    }
  }
}
</script>

<style scoped>
.v-list-item {
  transition: transform 0.3s ease;
}

.v-list-item:hover {
  transform: scale(1.05);
}
</style>
